.header__banner {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #68CEBC;
  color: #fff;
  font-weight: 700;
  min-height: 56px;
  font-size: 17px;
  padding: 0 20px;
  position: relative;
  @media (max-width: 767px) {
    font-size: 12px;
    min-height: 42px;
  }
}

.header__banner__close {
  composes: marketplaceModalCloseStyles from global;

  @media (max-width: 767px) {
    padding-top: 15px;
  }
  top: 50%;
  transform: translateY(-50%);
  position: absolute !important;
}


.closeIcon {
  fill: #fff;
}


.signup_banner {
  color: #fff;
}
